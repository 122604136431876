<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"/>
    <!-- 遮罩层 -->
    <div id="overlay" :class="{ active: showOverlay }"></div>
  </div>

</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { getTempleInfoData } from '@/api/modules/temple'
import { getQueryParam } from '@/utils/url'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getShare } from '@/api/modules/shareApi'
import wxShareApi from '@/utils/wxShare'
import { setShowToast } from '@/utils/request'
export default {
  // import 引入的组件需要注入到对象中才能使用
  name: 'App',
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {}
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('temple', ['templeInfoData']),
    ...mapState(['showOverlay']),
    ...mapState('wxShare',['shareData']),
    ...mapState('device',['device']),
    ...mapGetters({
      templeName: 'temple/getTempleName'
    }),
    templeId () {
      return getQueryParam('templeId') || this.templeInfoData.templeId || ''
    }
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations('temple', ['setTempleData']),
    ...mapMutations('wxShare',['setShareData']),
    async initTempleData () {
      if (!this.templeId) {
        // 弹窗提示
        this.$toast.fail({
          message: '寺庙id不存在！'
        })
        return
      }
      const { data: { data } } = await getTempleInfoData(this.templeId)
      this.setTempleData(data)
      document.title = data.templeName
    },
    async goShare() {
      //排除哪些路径不分享
      // 获取当前路径
      const currentPath = this.$route.path;
      // 定义不分享的路径列表
      const excludedPaths = ['/product']; // 添加你需要排除的路径
      // 判断当前路径是否在排除列表中
      if (excludedPaths.includes(currentPath)) {
        console.log('当前路径不分享');
        return;
      }

      if(!this.shareData){
        const { data:{data} } = await getShare();
        this.setShareData(data)
      }
      let imgurls = 'https://cdn.zjbbkj.com/lianhua.png'; //图片地址
      let titles = `${this.templeName} | 供灯祈福 | 点亮祈福灯`; //  = "微信第一次分享";
      let descs =  this.shareData?.shareContent || '  祈福招好运点灯保平安  点亮心灯 心想事成'; //  = "看看分享成功了吗";
      let originUrl = window.location.origin
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if(isIOS){
        originUrl = 'https://recept.zjbbkj.com'
      }
      let path = `${originUrl}/home?templeId=${this.templeId}`;
      if(window.location.pathname === "/devicelight"){
        path = `${originUrl}/devicelight?IMEIID=${getQueryParam('IMEIID') || this.device.deviceImei}`;
      }
      await wxShareApi(path, imgurls, titles, descs);
      setShowToast(true);
    },
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    if (this.templeId === '' || Object.keys(this.templeInfoData).length !== 0) {
      return
    }
    this.initTempleData()
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
    //获取环境 查看是否是微信环境
    const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
    if(isWeChat){
      setTimeout(() => {
        // 执行任务的代码
        setShowToast(false)
        this.goShare();
      }, 1000);
    }
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
    //window.removeEventListener('resize', this.handleResize);
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  },

}
</script>

<style scoped lang="less">
#app{
  max-width: 750px;
}
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
}

#overlay.active {
  display: block;
}

/* 浅色模式 */
#overlay {
  background-color: #f0f0f0;
}

/* 暗色模式 */
@media (prefers-color-scheme: dark) {
  #overlay {
    background-color: #1a1a1a;
  }
}



</style>
