import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/vant-ui'
import '@/styles/reset.css'
import VueWechatTitle from 'vue-wechat-title'
import scroll from 'vue-seamless-scroll'
import '@/icons' // icon
import dayjs from "dayjs"
import vConsole from 'vconsole'


Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs
// Vue.prototype.$vConsole= new vConsole()
Vue.use(VueWechatTitle)
Vue.use(scroll)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
