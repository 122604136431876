export default {
  namespaced: true,
  state:{
    orderListData: [],
  },
  mutations:{
      setOrderListData(state,data){
        state.orderListData = data
      }
  },
  actions:{

  },
  getters:{}
}
