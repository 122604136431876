/*
* 封装本地存储操作模块
* */


//存储数据
export const getLocalItem = name => {
  const data = window.localStorage.getItem(name)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

export const getSessionItem = name => {
  const data = window.sessionStorage.getItem(name)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

//获取数据
export const setLocalItem = (name, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(name, value)
}

export const setSessionlItem = (name, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.sessionStorage.setItem(name, value)
}

//删除数据
export const removeLocalItem = name => {
  window.localStorage.removeItem(name)
}

export const removeSessionItem = name => {
  window.sessionStorage.removeItem(name)
}


