import axios from "axios";
import { Toast } from 'vant';
import qs from 'qs';
import store from '@/store'

const instance = axios.create({
  timeout: 15000,
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers:{},
  validateStatus: function (status) {
    return status >= 200 && status < 500; // 处理所有状态码，包括 429
  }
});

let showToast = true;
let loadingToast = null;

export function setShowToast(isShow) {
  showToast = isShow;
}

export function hideLoading() {
  if (loadingToast) {
    loadingToast.clear();
    loadingToast = null;
  }
}

/*
* 请求拦截器
*  */
instance.interceptors.request.use(
  function(config) {
    if (showToast) {
      loadingToast = Toast.loading({
        message: '加载中...',
        duration: 0
      });
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);


/*
* 响应拦截器
*  */
instance.interceptors.response.use(
  function(response) {
    hideLoading();
    if(response.status === 429){
      // 处理限流的情况
      Toast.fail('访问人数过多，请稍后重试~');
      return response;
    }
    if(response.data.code === 999){
      // 处理限流的情况
      Toast.fail({
        message: response.data.message,
        duration: 2000,
      });
    }
    return response;
  },
  function(err) {
    Toast.fail({
      message: '请求超时~'
    });
    hideLoading();
    store.commit('pay/setPaying',false)
    return Promise.reject(err);
  }
);


export function get(url, params) {
  return instance.get(url, { params });
}

export function post(url, data) {
  return instance.post(url, qs.stringify(data));
}

export function getPath() {
  return path;
}

export default instance;
