import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getQueryParam } from '@/utils/url'
import { removeSessionItem } from '@/utils/storage'
import dayjs from 'dayjs' // 导入 dayjs 库

const Login = () => import('@/views/Login/index.vue')
const Layout = () => import('@/views/Layout/index.vue')
const Home = () => import('@/views/Layout/Home/index.vue')
const DeviceList = () => import('@/views/DeviceList/index.vue')
const About = () => import('@/views/Layout/About/index.vue')
const DeviceLight = () => import('@/views/DeviceList/DeviceLight/index.vue')
const My = () => import('@/views/Layout/My/index.vue')
const More = () => import('@/views/Layout/More/index.vue')
const Buddha = () => import('@/views/Layout/Buddha/index.vue')

const NotFound = () => import('@/views/NotFound/index.vue')

const option = () => import('@/views/Layout/My/Option/index.vue')

const success = () => import('@/components/Success/index.vue')

const PaySuccess = () => import('@/components/Pay/PaySuccess.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: resovle => require(['@/views/Login'],resovle),
    meta: {
      title: '登录',
      login_require: false,
    },
  },
  {
    path: '/',
    component: resovle => require(['@/views/Layout'],resovle),
    redirect: '/home',
    meta: {
      title: '扫码点灯',
      login_require: false,
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: resovle => require(['@/views/Layout/Home'],resovle),
        meta: {
          title: '扫码点灯',
          login_require: false,
        }
      },
      {
        path: '/more',
        name: 'more',
        component: resovle => require(['@/views/Layout/More'],resovle),
        meta: {
          title: '更多祈福',
          login_require: false,
        }
      }, {
        path: '/buddha',
        name: 'buddha',
        component: resovle => require(['@/views/Layout/Buddha'],resovle),
        meta: {
          title: '供佛供养',
          login_require: false,
        }
      },
      {
        path: '/about',
        name: 'about',
        component: resovle => require(['@/views/Layout/About'],resovle),
        meta: {
          title: '寺庙简介',
          login_require: false,
        }
      },
      {
        path: '/my',
        name: 'my',
        component: resovle => require(['@/views/Layout/My'],resovle),
        meta: {
          title: '我的',
          login_require: false,
        },
      },
    ]
  },
  {
    path: '/devicelist',
    name: 'devicelist',
    component: resovle => require(['@/views/DeviceList'],resovle),
    meta: {
      title: '心灯列表',
      login_require: false,
    }
  },
  {
    path: '/devicelight',
    name: 'devicelight',
    component: resovle => require(['@/views/DeviceList/DeviceLight'],resovle),
    meta: {
      title: '扫码点灯',
      login_require: false,
    }
  },{
    path: '/pay/success',
    name: 'paySuccess',
    component: resovle => require(['@/components/Pay/PaySuccess.vue'],resovle),
    meta:{
      title: '点灯成功',
      login_require: false,
    }
  },
  {
    path: '/my/orderlist',
    name: 'orderlist',
    component: resovle => require(['@/views/Layout/My/OrderList/index.vue'],resovle),
    meta:{
      title: '订单列表',
      login_require: true,
    }
  },
  {
    path: '/my/templelist',
    name: 'templelist',
    component: resovle => require(['@/views/Layout/My/TempleList/index.vue'],resovle),
    meta:{
      title: '寺庙列表',
      login_require: true,
    }
  },
  {
    path: '/my/calendar',
    name: 'calendar',
    component: resovle => require(['@/components/Calendar/BuddhistCalendar.vue'],resovle),
    meta:{
      title: '佛历',
      login_require: true,
    }
  },
  {
    path: '/my/option',
    name: 'option',
    component: resovle => require(['@/views/Layout/My/Option'],resovle),
    meta: {
      title: '提交意见',
      login_require: true,
    },
  },
  {
    path: '/my/option/success',
    name: 'success',
    component: resovle => require(['@/components/Success'],resovle),
    meta: {
      title: '提交',
      login_require: true,
    },
  },
  {
    path: '/product',
    name: 'product',
    component: resovle => require(['@/views/Product'],resovle),
    meta: {
      title: '向商家付款',
      login_require: false,
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: resovle => require(['@/views/NotFound'],resovle),
    meta:{
      title: '页面不存在',
      login_require: false,
    }
  },
]

/* /!* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch *!/
// 先存储默认底层的push
const originPush = VueRouter.prototype.push
// 覆盖原型链的push
VueRouter.prototype.push = function(location,resolve,reject){
  // this:路由实例对象

  // 判断用户有没有传后面两个可选参数
  if( resolve || reject ){
    return originPush.call(this,location,resolve,reject)
  }else{// 用户只传了第一个参数
    /!*
    默认底层： catch()方法代码  throw err : 抛出异常
    吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
    *!/
    return originPush.call(this,location).catch(err => {
      //  throw err
    })
  }
} */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
  if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch((e)=>{})
}

const router = new VueRouter({
  mode:'history',
  routes
})



router.beforeEach((to, from, next) => {
  const requiresLogin = to.matched.some(record => record.meta.login_require);

  if (requiresLogin && !store.state.user.userInfo.token && to.path !== '/login') {
    // 如果需要登录且用户未登录，并且不是跳转到登录页，则重定向到登录页
    next('/login');
  } else {
    // 用户已登录或页面不需要登录，允许访问
    next();
  }
});



/*
* 前置路由守卫
* */
router.beforeEach( async (to, from, next) => {
  const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
  const loginTime = store.state.user.loginTime
  if(!dayjs().isSame(dayjs(loginTime),'day')){
      //不是同一天
    store.commit('user/setOpenId','')
    store.commit('user/setLoginTime','')
  }
  const openId = store.state.user.openId
  //const openId = sessionStorage.getItem('openId');
   //拿到上次登录时间  如果不是今天或者loginTime为空那么就删除state中的openid 然后登录
  if (isWeChat && !openId) {
    // 显示遮罩层
    store.commit('setShowOverlay',true);
    // 判断当前路径是否包含 code 参数
    const code = to.query.code;
    const IMEIID = to.query.IMEIID;
    const templeId = to.query.templeId;
    let path = to.path;
    if (IMEIID && !templeId) {
      path = path + '?IMEIID=' + IMEIID;
    } else if (templeId) {
      path = path + '?templeId=' + templeId;
    }
    if (!code) {
      // 获取 code 的 URL
      //const res = await store.dispatch('queryCode', path);
      const appid = 'wxa488b1eb0254af21'
      // 拼接协议加域名，包括冒号和双斜杠
      const protocol = window.location.protocol; // 获取协议，如 "http:" 或 "https:"
      const host = window.location.host;
      const redirect_url = protocol + '//' + host + to.fullPath
      const red_url = encodeURIComponent(redirect_url)
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid
        + "&redirect_uri=" + red_url + "&response_type=code&scope=snsapi_base" + "&state=STATE" + "#wechat_redirect" ; //+ "state=" + path + "&forcePopup=true"
      return; // 跳出函数，不再执行后续的路由导航
    } else {
      // 通过 code 拿 openid
      // const res = await getOpenId(code)
      // sessionStorage.setItem("openId", res.data.data.openid);
      await store.dispatch('user/getOpenId',code)
      // 隐藏遮罩层
      store.commit('setShowOverlay',false);
    }
  }
  next(); // 执行后续的路由导航
});






/* 路由守卫 */
router.beforeEach(async (to, from, next) => {
  // 在进入路由前执行获取 templeId 的逻辑
  const IMEIID = getQueryParam('IMEIID')
  try{
    if (IMEIID && to.path !== '/devicelight') { // 添加路径检查条件
      next({ path: '/devicelight', query: { IMEIID } })
      return
    }
    const templeId = getQueryParam('templeId')
    if (templeId) {
      //判断是否相等
      if (!store.state.temple.templeId === templeId) {
        //换寺庙了 要初始化数据
        removeSessionItem('sessionStorageKey')
        // store.commit('temple/setTempleData', {})   //清空寺庙信息
        // store.commit('order/setOrderListData',[])  //清空订单信息
        // store.commit('device/setDeviceListData',[])//清空设备信息
        // store.commit('setRedAll',false)
      }
      store.commit('temple/setTempleId', templeId)
    }
  }catch (error){
    console.error('Failed to fetch templeId', error)
  }
  next()

})




export default router
