export default {
  namespaced: true,
  state:{
      prayList:[],
  },
  mutations:{
    setPrayList(state,data){
      state.prayList = data
    }

  },
  actions:{

  },
  getters:{

  }
}
