import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import temple from '@/store/modules/temple'
import pay from '@/store/modules/pay'
import order from '@/store/modules/order'
import device from '@/store/modules/device'
import combo from '@/store/modules/combo'
import pray from '@/store/modules/pray'
import env from '@/store/modules/env'
import createPersistedState from 'vuex-persistedstate'
import local from '@/store/modules/local'
import wxShare from '@/store/modules/wxShare'
import productQr from '@/store/modules/productQr'

Vue.use(Vuex)

//持久化插件persistedstate配置
const sessionStoragePlugin = createPersistedState({
  key: 'sessionStorageKey', // Session Storage 的 key
  storage: window.sessionStorage, // 使用 Session Storage 存储
  paths: ['temple', 'order', 'device', 'combo', 'pray', 'pay','env','wxShare','productQr'], // 保存在 Session Storage 中的模块或状态路径
})

const localStoragePlugin = createPersistedState({
  key: 'localStorageKey', // Local Storage 的 key
  storage: window.localStorage, // 使用 Local Storage 存储
  paths: ['user','local'], // 仅保存在 Local Storage 中的模块或状态路径
})

export default new Vuex.Store({
  plugins: [sessionStoragePlugin, localStoragePlugin],
  state: {
    prayName: '',
    prayComment: '身体健康万事如意',
    comboId: '',
    comboContent: '',
    lightingArr: [],
    deviceType: '',
    isRedAll: false,
    showOverlay: false,
  },
  mutations: {
    setPrayName (state, data) {
      if (data && data.trim() !== '') {
        state.prayName = data.trim();
      } else {
        state.prayName = '';
      }
    },
    setPrayComment (state, data) {
      if (data && data.trim() !== '') {
        state.prayComment = data.trim();
      } else {
        state.prayComment = '';
      }
    },
    setComboId (state, data) {
      state.comboId = data
    },
    setComContent (state, data) {
      state.comboContent = data
    },
    setLightingArr (state, data) {
      state.lightingArr = data
    },
    setDeviceType (state, data) {
      state.deviceType = data
    },
    setRedAll (state, data) {
      state.isRedAll = data
      if (state.isRedAll) {
        if (state.deviceType === '3') {
          state.lightingArr = Array.from({ length: 12 }, (_, index) => index.toString())
        } else if (state.deviceType === '4') {
          state.lightingArr = Array.from({ length: 24 }, (_, index) => index.toString())
        }
      }
    },
    setShowOverlay(state,data){
      state.showOverlay = data
    },
  },
  actions: {},
  getters: {
    lightingContent: state => {
      let content = ''
      if (state.deviceType === '3' && state.lightingArr.length === 12 || state.deviceType === '4' && state.lightingArr.length === 24) {
        content = '全部'
        if (state.isRedAll) {
          content = '满堂红'
        }
      } else {
        content = state.lightingArr.map(item => `【${+item + 1}】`).join(',')
        content = content && content.trim() !== '' ? content + '号心灯' : ''
      }
      return content
    }
  },
  modules: {
    user,
    temple,
    pay,
    order,
    device,
    combo,
    pray,
    env,
    local,
    wxShare,
    productQr,
  }
})
